$plp-tools-border: 1px solid color(dark);
$plp-refinements-cta-border-right: $plp-tools-border;
$plp-results-view-switcher-border-right: $plp-tools-border;
$plp-results-view-switcher-padding: rem-calc(5 0);
$plp-sorting-field-text-transform: uppercase;
$plp-tools-border-bottom--large: none;
$plp-results-count-font: 100 #{rem-calc(16)} / 1 $font-primary;
$plp-pagination-margin--large: rem-calc(60 0 30);
$plp-bottom-description-font--large: 300 #{rem-calc(16)} / 1.25 $font-primary;
$plp-bottom-description-padding: rem-calc(0 25);
$plp-bottom-description-font: #{rem-calc(14)} / 1.29 $font-primary;
$plp-bottom-description-margin: rem-calc(45 0);
$plp-bottom-description-margin--large: rem-calc(57 0);

@import "@lora/04-layout/plp";